<template>
  <v-scale-transition>
    <kits-panel
      :kits-items="dealershipKits"
      :breadcrumbs="breadcrumbs"
    >
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Dealership
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
// import security from '@/security'

const formTitle = 'Dealership'

export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
    }
  },
  computed: {
    dealershipKits: function () {
      const menus = []

      // if (security.me.dealerRank() === 'Area Dealer' || security.me.dealerRank() === 'Master Dealer' || security.me.nuDealer()) {
      menus.push({
        icon: 'mdi-account-tie',
        path: '/dealership_appoint',
        title: 'Appoint New Dealer',
        desc: 'Upgrade existing subscriber to become a dealer.',
      })
      // }
      menus.push(
        {
          icon: 'keyboard_tab',
          path: '/dealership_transfer_pin',
          title: 'Transfer Dealership PIN',
          desc: 'Transfer dealership PIN to other dealer.',
        },
        {
          icon: 'mdi-view-list',
          path: '/dealership_pin_record',
          title: 'Dealership PIN Record',
          desc: 'View dealership PIN transaction records.',
        },
        {
          icon: 'mdi-account-tie',
          path: '/dealership_subscription',
          title: 'Annual Subscription',
          desc: ' Subscription for existing dealer.',
        },
      )
      return menus
    },
  },
}
</script>
